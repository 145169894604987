import { useState } from "react";
import Image from "../../../../../components/Image";
import YzModal from "../../../../../components/pc/YzModal";
import "./index.less";

const PDIntroduce = ({ data }) => {
  // 立即咨询弹窗
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <div
      className="pd_introduce"
      style={{
        backgroundImage: `url(${require("./assets/bg_business@2x.png")})`,
      }}
    >
      <span className="pd_introduce_title animate__animated animate__slideInUp">
        功能介绍
      </span>
      <div className="pd_introduce_box">
        <div className="pd_introduce_left animate__animated animate__slideInUp">
          <Image className="pd_introduce_img" src={data?.cover ?? ""} alt="" />
        </div>
        <div className="pd_introduce_right animate__animated animate__slideInUp">
          <div className="pd_introduce_right_item" style={{ marginTop: 0 }}>
            <div className="pd_introduce_right_header">
              <div className="pd_introduce_right_divider color_main" />
              <span className="pd_introduce_right_title">应用场景</span>
            </div>
            <div className="pd_introduce_right_content">{data?.content1}</div>
          </div>
          <div className="pd_introduce_right_item">
            <div className="pd_introduce_right_header">
              <div className="pd_introduce_right_divider color_main" />
              <span className="pd_introduce_right_title">应用功能</span>
            </div>
            <div className="pd_introduce_right_content">{data?.content2}</div>
          </div>
          <div className="pd_introduce_right_item">
            <div className="pd_introduce_right_header">
              <div className="pd_introduce_right_divider color_main" />
              <span className="pd_introduce_right_title">应用特点</span>
            </div>
            <div className="pd_introduce_right_content">{data?.content3}</div>
          </div>
          <div
            className="btn color_btn"
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <span>立即咨询</span>
          </div>
        </div>
      </div>
      {/* 立即咨询弹窗 */}
      <YzModal open={isModalOpen} onCancel={() => setIsModalOpen(false)} />
    </div>
  );
};

export default PDIntroduce;

import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { setUserInfo } from "../../../../../store/user";
import HTTP from "../../../../../utils/Http";
import "./index.less";

const PersonalEditPwd = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.App?.token);
  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo); // 用户信息

  const [oldPwd, setOldPwd] = useState("");
  const [newPwd, setNewPwd] = useState("");
  const [confirmPwd, setConfirmPwd] = useState("");

  const [loading, setLoading] = useState(false);
  const getSavePwd = async () => {
    if (oldPwd === "")
      return message.error({
        content: "原密码不能为空",
        style: { marginTop: "10vh" },
      });
    if (newPwd === "")
      return message.error({
        content: "新密码不能为空",
        style: { marginTop: "10vh" },
      });
    if (confirmPwd === "")
      return message.error({
        content: "确认密码不能为空",
        style: { marginTop: "10vh" },
      });
    if (newPwd !== confirmPwd)
      return message.error({
        content: "两次密码不一致",
        style: { marginTop: "10vh" },
      });
    await getSavePwdApi();
  };
  const getSavePwdApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/passwordChange" + `?t=${new Date().getTime()}`,
        {
          token,
          old_password: oldPwd,
          new_password: confirmPwd,
        }
      );
      if (res?.status === 10000) {
        setOldPwd("");
        setNewPwd("");
        setConfirmPwd("");
        await getUserInfoApi();
        message.success({
          content: res?.message,
          style: { marginTop: "10vh" },
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const setPwd = async () => {
    if (newPwd === "")
      return message.error({
        content: "密码不能为空",
        style: { marginTop: "10vh" },
      });
    if (confirmPwd === "")
      return message.error({
        content: "确认密码不能为空",
        style: { marginTop: "10vh" },
      });
    if (newPwd !== confirmPwd)
      return message.error({
        content: "两次密码不一致",
        style: { marginTop: "10vh" },
      });
    await setPwdApi();
  };
  const setPwdApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/passwordSet" + `?t=${new Date().getTime()}`,
        {
          token,
          new_password: confirmPwd,
        }
      );
      if (res?.status === 10000) {
        setOldPwd("");
        setNewPwd("");
        setConfirmPwd("");
        await getUserInfoApi();
        message.success({
          content: res?.message,
          style: { marginTop: "10vh" },
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getUserInfoApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/userInfo" + `?t=${new Date().getTime()}`,
        { token }
      );
      if (res.status === 10000) {
        await dispatch(setUserInfo(res?.data));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="personal_edit_pwd">
      <span className="personal_edit_pwd_title">
        {userInfo?.password == "0" ? "设置登录密码" : "修改登录密码"}
      </span>
      {userInfo?.password == "0" ? (
        <>
          <div className="personal_edit_pwd_row">
            <span className="personal_edit_pwd_row_title">密码</span>
            <div className="yz_input">
              <input
                type={"text"}
                placeholder="请填写密码"
                value={newPwd}
                maxLength={16}
                onInput={(e) => {
                  setNewPwd(e?.target?.value);
                }}
              />
            </div>
          </div>
          <div className="personal_edit_pwd_row">
            <span className="personal_edit_pwd_row_title">确认密码</span>
            <div className="yz_input">
              <input
                type={"text"}
                placeholder="请再次填写密码"
                value={confirmPwd}
                maxLength={16}
                onInput={(e) => {
                  setConfirmPwd(e?.target?.value);
                }}
              />
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="personal_edit_pwd_row">
            <span className="personal_edit_pwd_row_title">原密码</span>
            <div className="yz_input">
              <input
                type={"text"}
                placeholder="请填写原密码"
                value={oldPwd}
                maxLength={16}
                onInput={(e) => {
                  setOldPwd(e?.target?.value);
                }}
              />
            </div>
          </div>
          <div className="personal_edit_pwd_row">
            <span className="personal_edit_pwd_row_title">新密码</span>
            <div className="yz_input">
              <input
                type={"text"}
                placeholder="请填写新密码"
                value={newPwd}
                maxLength={16}
                onInput={(e) => {
                  setNewPwd(e?.target?.value);
                }}
              />
            </div>
          </div>
          <div className="personal_edit_pwd_row">
            <span className="personal_edit_pwd_row_title">确认密码</span>
            <div className="yz_input">
              <input
                type={"text"}
                placeholder="请再次填写新密码"
                value={confirmPwd}
                maxLength={16}
                onInput={(e) => {
                  setConfirmPwd(e?.target?.value);
                }}
              />
            </div>
          </div>
        </>
      )}
      <div className="btn">
        <div
          className="btn_save color_btn"
          onClick={userInfo?.password == "0" ? setPwd : getSavePwd}
        >
          <span>{userInfo?.password == "0" ? "设置" : "保存"}</span>
        </div>
      </div>
    </div>
  );
};

export default PersonalEditPwd;

import { useState, useEffect } from 'react'
import { Modal, message } from 'antd'
import Image from '../../Image'
import HTTP from '../../../utils/Http'
import './index.less'
import { useSelector } from 'react-redux'

const YzModalTreaty = ({
  open=false,
  onCancel=() => {}
}) => {
  const commonConfig = useSelector(state => state?.App?.commonConfig)
  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const getArticeDetailApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/detail'+`?t=${new Date().getTime()}`, { id: commonConfig?.zhucexieyi_key })
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  useEffect(()=>{
    getArticeDetailApi()
  },[])

  return (
    <Modal
      className='yz_modal_treaty'
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className='modal_close' onClick={onCancel}>
        <Image className='modal_close_img' src={require('./assets/t_icon_top_close@2x.png')} alt='' />
      </div>
      <div
        className='modal_content'
        dangerouslySetInnerHTML={{
          __html: data?.content
        }}
      />
    </Modal>
  )
}

export default YzModalTreaty
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import { dataVerification } from "../../../../../utils/formatChecks";
import HTTP from "../../../../../utils/Http";
import "./index.less";

const LoginBox = () => {
  const navigate = useNavigate();

  const [tel, setTel] = useState("");
  const [code, setCode] = useState("");
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");

  const [countDown, setCountDown] = useState(60); // 获取验证码倒计时
  const [isGetCode, setIsGetCode] = useState(true); // 是否可获取验证码
  // 验证码获取倒计时
  const countDownHandle = () => {
    let t = countDown;
    setIsGetCode(false);
    const timer = setInterval(() => {
      t--;
      setCountDown(t);
      if (t === 0) {
        clearInterval(timer);
        setIsGetCode(true);
        setCountDown(60);
      }
    }, 1000);
  };
  const [loading, setLoading] = useState(false);
  const getCode = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    await getVerifyMobileApi();
  };
  const getVerifyMobileApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/verifyMobile" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        // 已注册
        await getCodeApi();
      } else {
        // 未注册
        message.warning({
          content: res?.message,
          style: { marginTop: "10vh" },
        });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getCodeApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/sms/sendCommonSMS" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        countDownHandle();
        message.success({
          content: "验证码已发送",
          style: { marginTop: "10vh" },
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getPwd = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    if (code === "")
      return message.error({
        content: "验证码不能为空",
        style: { marginTop: "10vh" },
      });
    if (code.length < 6)
      return message.error({
        content: "验证码格式错误",
        style: { marginTop: "10vh" },
      });
    if (pwd1 === "" || pwd2 === "")
      return message.error({
        content: "密码不能为空",
        style: { marginTop: "10vh" },
      });
    if (pwd1 !== pwd2)
      return message.error({
        content: "两次密码不一致",
        style: { marginTop: "10vh" },
      });
    await getPwdApi();
  };
  const getPwdApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/resetPassword" + `?t=${new Date().getTime()}`,
        {
          mobile: tel,
          code: code,
          password: pwd2,
        }
      );
      if (res.status === 10000) {
        message.success({ content: "修改成功", style: { marginTop: "10vh" } });
        navigate("/login", { replace: true });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div className="login_section_m_header">
        <span className="login_section_m_title login_section_m_title_active">
          找回密码
        </span>
      </div>
      <div className="login_section_m_body">
        <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
          <input
            type={"tel"}
            placeholder="请填写手机号"
            value={tel}
            maxLength={11}
            onInput={(e) => {
              setTel(e?.target?.value);
            }}
          />
        </div>
        <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
          <input
            type={"tel"}
            placeholder="请填写验证码"
            value={code}
            maxLength={6}
            onInput={(e) => {
              setCode(e?.target?.value);
            }}
          />
          <span
            className={`btn_code_m color_main_title ${!isGetCode && "no_code"}`}
            onClick={() => {
              if (isGetCode) getCode();
            }}
          >
            {isGetCode ? "获取验证码" : `重新获取${countDown}`}
          </span>
        </div>
        <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
          <input
            type={"text"}
            placeholder="请填写新密码"
            value={pwd1}
            maxLength={16}
            onInput={(e) => {
              setPwd1(e?.target?.value);
            }}
          />
        </div>
        <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
          <input
            type={"text"}
            placeholder="请再次填写新密码"
            value={pwd2}
            maxLength={16}
            onInput={(e) => {
              setPwd2(e?.target?.value);
            }}
          />
        </div>
        <div className="btn_login_m color_btn" onClick={getPwd}>
          确认修改
        </div>
      </div>
    </>
  );
};

const LoginSectionMobile = () => {
  return (
    <section
      className="login_section_m"
      style={{
        backgroundImage: `url(${require("./assets/bg_login_m@3x.png")})`,
      }}
    >
      <LoginBox />
    </section>
  );
};

export default LoginSectionMobile;

import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import Image from "../../Image";
import "./index.less";

const YzModalProduct = ({ open = false, onCancel = () => {} }) => {
  const navigate = useNavigate();
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置
  return (
    <Modal
      className="yz_modal_product"
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className="ymp_modal_header">
        <span className="ymp_modal_title">您还没有登录</span>
        <div className="ymp_modal_close" onClick={onCancel}>
          <Image src={require("./assets/t_icon_top_close@2x.png")} alt="" />
        </div>
      </div>
      <div className="ymp_modal_body">
        <span>请先登录或注册再进行此操作</span>
      </div>
      <div className="ymp_modal_footer">
        {commonConfig && commonConfig.register_switch === "1" && (
          <div
            className="btn"
            onClick={() => {
              navigate("/register");
            }}
          >
            注册账号
          </div>
        )}
        {commonConfig &&
          commonConfig.login_switch === "1" &&
          commonConfig.register_switch === "1" && <div className="divider" />}
        {commonConfig && commonConfig.login_switch === "1" && (
          <div
            className="btn btn_login"
            onClick={() => {
              navigate("/login");
            }}
          >
            立即登录
          </div>
        )}
      </div>
    </Modal>
  );
};

export default YzModalProduct;

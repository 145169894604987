import { useState, useEffect, useRef } from 'react'
import Footer from '../../../../../components/pc/Footer'
import BuoyBox from '../../../../../components/pc/BuoyBox'
import NDHeader from '../NDHeader'
import NDMain from '../NDMain'
import './index.less'

const NewsDetailsSection = ({
  title,
  category_id
}) => {
  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = (animation=true) => { // 置顶事件
    if(animation) {
      let myTimer = setInterval(() => {
        boxRef.current.scrollTop -= 60
        if(boxRef.current.scrollTop<=0){
          boxRef.current.scrollTop = 0
          window.clearInterval(myTimer)
        }
      }, 10)
    } else {
      boxRef.current.scrollTop = 0
    }
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='news_details_section'>
      <div className='news_details_box'>
        <NDHeader title={title} />
        <NDMain category_id={category_id} />
        <Footer />
        <BuoyBox isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default NewsDetailsSection
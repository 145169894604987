import { createSlice } from '@reduxjs/toolkit'
import qs from 'qs'

export const menuSlice = createSlice({
  name: 'menu',
  initialState: {
    menuIndex: localStorage?.getItem('menuIndex') &&
               localStorage?.getItem('menuIndex') !== '' ?
               localStorage?.getItem('menuIndex')-0 : 0,
    menuList: localStorage?.getItem('menuList') &&
              localStorage?.getItem('menuList') !== '' ?
              qs.parse(localStorage?.getItem('menuList')) : null
  },
  reducers: {
    setMenuList: (state, action) => {
      if (action.payload && action.payload.length > 0) {
        state.menuList = action.payload
        localStorage.setItem('menuList', qs.stringify(action.payload))
      } else {
        state.menuList = null
        localStorage.removeItem('menuList')
      }
    },
    setMenuIndex: (state, action) => {
      state.menuIndex = action.payload
      localStorage.setItem('menuIndex', action.payload)
    }
  }
})

export const {
  setMenuIndex,
  setMenuList
} = menuSlice.actions

export default menuSlice.reducer
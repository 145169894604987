// import { Helmet } from 'react-helmet'
import { Helmet } from 'react-helmet-async'

const Head = ({
  children
}) => {
  return (
    <Helmet>
      <meta name='applicable-device' content='pc,mobile'></meta>
      <meta property='og:type' content='article' ></meta>
      { children }
    </Helmet>
  )
}

export default Head
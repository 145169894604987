import { useState, useEffect } from 'react'
import { message } from 'antd'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getDocumentTitle } from '../../utils/getDocumentTitle'
import Head from '../../components/Head'
import { flexibleWidth } from '../../utils/flexible'
import HTTP from '../../utils/Http'
import './index.less'

const Treaty = ({
  bg,
  bg_title,
  bg_content,
  pageType
}) => {
  const location = useLocation()
  const commonConfig = useSelector(state => state?.App?.commonConfig)
  const menuList = useSelector((state) => state?.Menu?.menuList)
  const [menuId] = menuList.filter(f=>f.link===location?.pathname).map(m=>m.key)

  let [plat, setPlat] = useState(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
  // 页面配置
  const [windowWidth, setWindowWidth] = useState(document.documentElement.clientWidth) // 页面宽度
  useEffect(() => { // 页面宽度变化
    flexibleWidth()
    setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
    setWindowWidth(document.documentElement.clientWidth)
    window.addEventListener('resize', () => {
      flexibleWidth()
      setPlat(navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))
      setWindowWidth(document.documentElement.clientWidth)
    }, true)
  }, [])

  const [data, setData] = useState()
  const [loading, setLoading] = useState(false)
  const getArticeDetailApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/article/detail'+`?t=${new Date().getTime()}`, { id: commonConfig?.zhucexieyi_key })
      if (res.status === 10000) {
        setData(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(()=>{
    getArticeDetailApi()
  },[])
  
  return (
    <>
    <Head>
      <title>{getDocumentTitle(location?.pathname, menuList, commonConfig?.web_title)??''}</title>
      <meta name='keywords' content={commonConfig?.web_keywords ?? '关键词'} />
      <meta name='description' content={commonConfig?.web_description ?? '描述内容'} />
      <meta
        property='og:title'
        content={getDocumentTitle(location?.pathname, menuList, commonConfig?.web_title)??''}
      ></meta>
      <meta property='og:description' content={commonConfig?.web_description ?? '描述内容'} />
      <meta property='og:image' content={commonConfig?.web_icon ?? 'https://www.easyspark.cn/favicon.ico'} ></meta>
    </Head>
    <div className='treaty'>
      {
        !plat && windowWidth>=821 ?
          <div className='treaty_pc'>
            <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
          </div> :
          <div className='treaty_mobile'>
            <div dangerouslySetInnerHTML={{ __html: data?.content }}></div>
          </div>
      }
    </div>
    </>
  )
}

export default Treaty
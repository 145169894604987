import { lazy, Suspense } from "react";
import { useSelector } from "react-redux";
import { useRoutes, Navigate } from "react-router-dom";
import { loaderComponent } from "./loaderComponent.js";
import ProductDetails from "../pages/ProductDetails";
import ProductPlusDetails from "../pages/ProductPlusDetails";
import NewsDetails from "../pages/NewsDetails";
import Search from "../pages/Search";
import Login from "../pages/Login";
import LoginWeChar from "../pages/LoginWeChat";
import LoginPassword from "../pages/LoginPassword";
import LoginRegister from "../pages/LoginRegister";
import LoginTelBinding from "../pages/LoginTelBinding";
import Personal from "../pages/Personal";
import Treaty from "../pages/Treaty";

const GetRoutes = () => {
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置
  const menuList = useSelector((state) => state?.Menu?.menuList);
  let userRouter = [];
  if (menuList && menuList?.length > 0) {
    userRouter = menuList.map((m) => {
      const OtherComponent = lazy(() =>
        loaderComponent(() => import("../pages/" + m?.pageRoute))
      );
      return {
        path: m.link,
        element: (
          <Suspense fallback={<></>}>
            <OtherComponent
              bg={m?.backgroud !== "" ? m?.backgroud : ""}
              bg_title={m?.bg_title !== "" ? m?.bg_title : ""}
              bg_content={m?.bg_content !== "" ? m?.bg_content : ""}
              pageType={m?.pageType !== "" ? m?.pageType : "0"}
              category_id={m?.category_id !== "" ? m?.category_id : "0"}
            />
          </Suspense>
        ),
      };
    });
  }
  return useRoutes([
    ...userRouter,
    ...[
      {
        path: "/product/details/:type/:id",
        element: <ProductDetails />,
      },
      {
        path: "/product_plus/details/:type/:id",
        element: <ProductPlusDetails />,
      },
      {
        path: "/news/details/:type/:id",
        element: <NewsDetails />,
      },
      {
        path: "/search",
        element: <Search />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      {
        path: "/login/wechat",
        element: <LoginWeChar />,
      },
      {
        path: "/login/password",
        element: <LoginPassword />,
      },
      {
        path: "/login/tel_binding",
        element: <LoginTelBinding />,
      },
      {
        path: "/register",
        element: <LoginRegister />,
      },
      {
        path: "/personal",
        element: <Personal />,
      },
      {
        path: "/treaty",
        element: <Treaty />,
      },
      {
        path: "/*",
        element: (
          <Navigate
            replace
            to={
              commonConfig?.default_route !== ""
                ? commonConfig?.default_route
                : "/"
            }
          />
        ),
      },
    ],
  ]);
};

export default GetRoutes;

import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Modal, message } from "antd";
import { setToken, setExpireTime } from "../../../store/app";
import { setUserInfo } from "../../../store/user";
import { setMenuList } from "../../../store/menu";
import { dataVerification } from "../../../utils/formatChecks";
import QRCode from "qrcode.react";
import Image from "../../Image";
import HTTP from "../../../utils/Http";
import "./index.less";

let timerCode;
const YzModalLogin = ({ open = false, onCancel = () => {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置
  const token = useSelector((state) => state?.App?.token);
  const userInfo = useSelector((state) => state?.User?.userInfo);
  // 判断用户是否登录、然后看用户是否绑定了手机号，展示相应页面
  const [modalType, setModalType] = useState(0);

  const [wechat, setWeChat] = useState();
  const [isAuth, setIsAuth] = useState(false);
  const getWeChatCodeApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/wechatLoginURLAndID" + `?t=${new Date().getTime()}`
      );
      if (res.status === 10000) {
        setWeChat(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getWeChatLoginApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/loginByWechatAction" + `?t=${new Date().getTime()}`,
        { uuid: wechat?.uuid }
      );
      if (res.status === 10000) {
        // 已扫码登录成功
        setIsAuth(true);
        dispatch(setToken(res?.data?.token));
        dispatch(setExpireTime(res?.data?.expire));
        await getUserInfoApi(res?.data?.token);
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getUserInfoApi = async (token) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/userInfo" + `?t=${new Date().getTime()}`,
        { token }
      );
      if (res.status === 10000) {
        await dispatch(setUserInfo(res?.data));
        await getMenuListApi();
        if (res?.data?.mobile !== "") {
          // await getMenuListApi()
          // navigate(-1, { replace: true })
          // 关闭当前弹窗
          clearInterval(timerCode);
          onCancel();
        } else {
          // navigate('/login/tel_binding', { replace: true })
          // 打开绑定手机号
          setModalType(1);
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getMenuListApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/listByPosition" + `?t=${new Date().getTime()}`,
        { position: 1 }
      );
      if (res.status === 10000) {
        let target = res?.data?.map((item) => {
          return {
            key: item?.id,
            title: item?.name ?? "标题",
            link: item?.link ?? "/",
            backgroud: item?.backgroud,
            bg_title: item?.title,
            bg_content: item?.content,
            pageRoute: item?.text1,
            pageType: item?.text2,
            category_id: item?.category_id,
          };
        });
        dispatch(setMenuList(target));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const AuthHandle = () => {
    clearInterval(timerCode);
    if (!isAuth) {
      timerCode = setInterval(() => {
        getWeChatLoginApi();
      }, 2000);
    } else {
      clearInterval(timerCode);
    }
    // timerCode = setInterval(() => {
    //   getWeChatLoginApi()
    //   if(isAuth) clearInterval(timerCode)
    // }, 1000)
  };
  useEffect(() => {
    if (open) {
      getWeChatCodeApi();
      if (userInfo) {
        setModalType(1);
      } else {
        setModalType(0);
      }
    }
  }, [open, userInfo]);
  useEffect(() => {
    if (open) AuthHandle();
  }, [open, wechat, isAuth]);

  const [tel, setTel] = useState("");
  const [code, setCode] = useState("");
  const [countDown, setCountDown] = useState(60); // 获取验证码倒计时
  const [isGetCode, setIsGetCode] = useState(true); // 是否可获取验证码
  const [loading, setLoading] = useState(false);
  const countDownHandle = () => {
    // 验证码获取倒计时
    let t = countDown;
    setIsGetCode(false);
    const timer = setInterval(() => {
      t--;
      setCountDown(t);
      if (t === 0) {
        clearInterval(timer);
        setIsGetCode(true);
        setCountDown(60);
      }
    }, 1000);
  };
  const getCode = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    await getVerifyMobileApi();
  };
  const getVerifyMobileApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/verifyMobile" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        // 已注册
        message.warning({
          content: "当前手机号已注册",
          style: { marginTop: "10vh" },
        });
      } else {
        // 未注册
        await getCodeApi();
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getCodeApi = async () => {
    if (loading) return;
    setLoading(true);

    try {
      const res = await HTTP.postForm(
        "/api/v1/sms/sendCommonSMS" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        countDownHandle();
        message.success({
          content: "验证码已发送",
          style: { marginTop: "10vh" },
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const goNext = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    if (code === "")
      return message.error({
        content: "验证码不能为空",
        style: { marginTop: "10vh" },
      });
    if (code.length < 6)
      return message.error({
        content: "验证码格式错误",
        style: { marginTop: "10vh" },
      });
    await getTelBindApi();
  };
  const getTelBindApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/mobileBind" + `?t=${new Date().getTime()}`,
        {
          token,
          mobile: tel,
          code,
        }
      );
      if (res.status === 10000) {
        // 绑定手机号成功
        await getUserInfoApi();
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      className="yz_modal_login"
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: "rgba(0,0,0,0.5)" }}
      destroyOnClose={true}
      onCancel={() => {
        clearInterval(timerCode);
        onCancel();
      }}
      afterClose={() => {
        clearInterval(timerCode);
        onCancel();
      }}
    >
      <div className="ymp_modal_login_close" onClick={onCancel}>
        <Image src={require("./assets/t_icon_top_close@2x.png")} alt="" />
      </div>
      <div className="ymp_modal_login_header">
        <span className="ymp_modal_login_title">
          {modalType === 0 ? "请登录" : "绑定手机号"}
        </span>
        {modalType === 0 && (
          <span className="ymp_modal_login_vice_title">
            未注册的微信号将自动创建新的账号
          </span>
        )}
      </div>
      <div className="yz_modal_login_body">
        {modalType === 0 && (
          <>
            <QRCode value={wechat && wechat?.url} onClick={getWeChatCodeApi} />
            <span className="yz_modal_login_body_tips">微信扫码登录</span>
            {commonConfig &&
              (commonConfig?.acount_password_login === "1" ||
                commonConfig?.mobile_code_login === "1") && (
                <div
                  className="btn_login "
                  onClick={() => {
                    navigate("/login");
                  }}
                >
                  <span>账号登录注册</span>
                </div>
              )}
            <div className="agreement">
              <span>注册登录即表示同意 </span>
              <span
                className="register"
                onClick={() => {
                  window.open(`${window.myURL.URL}/treaty`, "_blank");
                }}
              >
                《注册协议》
              </span>
            </div>
          </>
        )}
        {modalType === 1 && (
          <>
            <div className="yz_input">
              <input
                type={"tel"}
                placeholder="请填写手机号"
                value={tel}
                maxLength={11}
                onInput={(e) => {
                  setTel(e?.target?.value);
                }}
              />
            </div>
            <div className="yz_input">
              <input
                type={"tel"}
                placeholder="请填写验证码"
                value={code}
                maxLength={6}
                onInput={(e) => {
                  setCode(e?.target?.value);
                }}
              />
              <span
                className={`btn_code color_main_title ${
                  !isGetCode && "no_code"
                }`}
                onClick={() => {
                  if (isGetCode) getCode();
                }}
              >
                {isGetCode ? "获取验证码" : `重新获取${countDown}`}
              </span>
            </div>
            <div className="btn_bind color_btn" onClick={goNext}>
              <span>绑定</span>
            </div>
          </>
        )}
      </div>
    </Modal>
  );
};

export default YzModalLogin;

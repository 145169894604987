import { createSlice } from '@reduxjs/toolkit'
import qs from 'qs'

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userInfo: localStorage.getItem('userInfo') &&
              localStorage.getItem('userInfo') !== '' ? qs.parse(localStorage.getItem('userInfo')) : null
  },
  reducers: {
    setUserInfo: (state, action) => {
      if (action.payload) {
        state.userInfo = action.payload
        localStorage.setItem('userInfo', qs.stringify(action.payload))
      } else {
        state.userInfo = null
        localStorage.removeItem('userInfo')
      }
    },
  }
})

export const {
  setUserInfo
} = userSlice.actions

export default userSlice.reducer
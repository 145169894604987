export const dataVerification = (type = "tel", value) => {
  if (type === "tel") {
    if (!value || value === "") return false
    
    // 座机号、手机号以及400、800
    return !/^((0\d{2,3}-?\d{7,8})|(1[3456789]\d{9})|(400-?\d{3}-?\d{4})|(800-?\d{3}-?\d{4}))$/.test(value)
  }
  if (type === "email") {
    if (value || value !== "")
      return !/^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)
    return false;
  }
}
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { setMenuList } from "../../../../../store/menu";
import { setToken, setExpireTime } from "../../../../../store/app";
import { setUserInfo } from "../../../../../store/user";
import YzModalTreaty from "../../../../../components/pc/YzModalTreaty";
import QRCode from "qrcode.react";
import HTTP from "../../../../../utils/Http";
import "./index.less";

let timer;

const LoginSection = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置
  // 协议弹窗
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [wechat, setWeChat] = useState();
  const [isAuth, setIsAuth] = useState(false);
  const [loading, setLoading] = useState(false);
  const getWeChatCodeApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/wechatLoginURLAndID" + `?t=${new Date().getTime()}`
      );
      if (res.status === 10000) {
        setWeChat(res?.data);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getWeChatLoginApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/loginByWechatAction" + `?t=${new Date().getTime()}`,
        { uuid: wechat?.uuid }
      );
      if (res.status === 10000) {
        // 已扫码登录成功
        setIsAuth(true);
        dispatch(setToken(res?.data?.token));
        dispatch(setExpireTime(res?.data?.expire));
        await getUserInfoApi(res?.data?.token);
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getUserInfoApi = async (token) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/userInfo" + `?t=${new Date().getTime()}`,
        { token }
      );
      if (res.status === 10000) {
        await getMenuListApi();
        await dispatch(setUserInfo(res?.data));
        if (res?.data?.mobile !== "") {
          navigate(-1, { replace: true });
        } else {
          navigate("/login/tel_binding", { replace: true });
        }
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getMenuListApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/listByPosition" + `?t=${new Date().getTime()}`,
        { position: 1 }
      );
      if (res.status === 10000) {
        let target = res?.data?.map((item) => {
          return {
            key: item?.id,
            title: item?.name ?? "标题",
            link: item?.link ?? "/",
            backgroud: item?.backgroud,
            bg_title: item?.title,
            bg_content: item?.content,
            pageRoute: item?.text1,
            pageType: item?.text2,
            category_id: item?.category_id,
          };
        });
        dispatch(setMenuList(target));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const AuthHandle = () => {
    clearInterval(timer);
    if (!isAuth) {
      timer = setInterval(() => {
        getWeChatLoginApi();
      }, 2000);
    } else {
      clearInterval(timer);
    }
  };

  useEffect(() => {
    getWeChatCodeApi();
  }, []);

  useEffect(() => {
    AuthHandle();
  }, [wechat, isAuth]);

  return (
    <section
      className="login_wechat_section"
      style={{
        backgroundImage: `url(${require("./assets/img_home_banner@2x.png")})`,
      }}
    >
      <div className="login_wechat_section_box">
        <div className="login_wechat_section_header">
          <span className="login_wechat_section_title">微信扫码登录</span>
          <span className="login_wechat_section_vice_title">
            未注册的微信号将自动创建新的账号
          </span>
        </div>
        <div className="login_wechat_section_body">
          <div className="login_wechat_section_code" onClick={getWeChatCodeApi}>
            <QRCode value={wechat && wechat?.url} />
          </div>
          {commonConfig &&
            (commonConfig?.acount_password_login === "1" ||
              commonConfig?.mobile_code_login === "1") && (
              <div
                className="login_wechat_btn_to_login"
                onClick={() => {
                  clearInterval(timer);
                  navigate("/login", { replace: true });
                }}
              >
                账号登录注册
              </div>
            )}
          <div className="login_wechat_agreement">
            <span className="login_wechat_agreement_label">
              注册登录即表示同意
            </span>
            <span
              className="login_wechat_btn_agreement"
              // onClick={() => { setIsModalOpen(true) }}
              onClick={() => {
                window.open(`${window.myURL.URL}/treaty`, "_blank");
              }}
            >
              《注册协议》
            </span>
          </div>
        </div>
      </div>
      {/* 协议弹窗 */}
      <YzModalTreaty
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </section>
  );
};

export default LoginSection;

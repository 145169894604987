import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { getDocumentTitle } from '../../utils/getDocumentTitle'
import Head from '../../components/Head'
import LoginHeader from '../../components/pc/LoginHeader'
import LoginSection from './components/pc/LoginSection'
import LoginFooter from '../../components/pc/LoginFooter'
import './index.less'

const LoginTelBinding = () => {
  const location = useLocation()
  const commonConfig = useSelector(state => state?.App?.commonConfig)
  const menuList = useSelector((state) => state?.Menu?.menuList)

  return (
    <>
    <Head>
      <title>{getDocumentTitle(location?.pathname, menuList, commonConfig?.web_title)??''}</title>
      <meta name='keywords' content={commonConfig?.web_keywords ?? '关键词'} />
      <meta name='description' content={commonConfig?.web_description ?? '描述内容'} />
      <meta
        property='og:title'
        content={getDocumentTitle(location?.pathname, menuList, commonConfig?.web_title)??''}
      ></meta>
      <meta property='og:description' content={commonConfig?.web_description ?? '描述内容'} />
      <meta property='og:image' content={commonConfig?.web_icon ?? 'https://www.easyspark.cn/favicon.ico'} ></meta>
    </Head>
    <div className='login_register'>
      <LoginHeader />
      <LoginSection />
      <LoginFooter />
    </div>
    </>
  )
}

export default LoginTelBinding
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { setUserInfo } from "../../../../../store/user";
import HTTP from "../../../../../utils/Http";
import { dataVerification } from "../../../../../utils/formatChecks";
import "./index.less";

const PersonalBindTel = () => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.App?.token);
  const userInfo = useSelector((state) => state?.User?.userInfo);

  const [tel, setTel] = useState(userInfo?.mobile ?? "");
  const [code, setCode] = useState("");
  const [countDown, setCountDown] = useState(60); // 获取验证码倒计时
  const [isGetCode, setIsGetCode] = useState(true); // 是否可获取验证码
  // 验证码获取倒计时
  const countDownHandle = () => {
    let t = countDown;
    setIsGetCode(false);
    const timer = setInterval(() => {
      t--;
      setCountDown(t);
      if (t === 0) {
        clearInterval(timer);
        setIsGetCode(true);
        setCountDown(60);
      }
    }, 1000);
  };
  const [loading, setLoading] = useState(false);
  const getCode = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    await getVerifyMobileApi();
  };
  const getVerifyMobileApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/verifyMobile" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        // 已注册
        message.warning({
          content: "当前手机号已注册",
          style: { marginTop: "10vh" },
        });
      } else {
        // 未注册
        await getCodeApi();
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getCodeApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/sms/sendCommonSMS" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        countDownHandle();
        message.success({
          content: "验证码已发送",
          style: { marginTop: "10vh" },
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const goNext = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    if (code === "")
      return message.error({
        content: "验证码不能为空",
        style: { marginTop: "10vh" },
      });
    if (code.length < 6)
      return message.error({
        content: "验证码格式错误",
        style: { marginTop: "10vh" },
      });
    await getTelBindApi();
  };
  const getTelBindApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/mobileBind" + `?t=${new Date().getTime()}`,
        {
          token,
          mobile: tel,
          code,
        }
      );
      if (res.status === 10000) {
        // 绑定手机号成功
        message.success({
          content: res?.message,
          style: { marginTop: "10vh" },
        });
        await getUserInfoApi();
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getUserInfoApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/user/userInfo" + `?t=${new Date().getTime()}`,
        { token }
      );
      if (res.status === 10000) {
        await dispatch(setUserInfo(res?.data));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="personal_edit_tel">
      <span className="personal_edit_tel_title">绑定手机号</span>
      <div className="personal_edit_tel_row">
        <span className="personal_edit_tel_row_title">手机号</span>
        <div className="yz_input">
          <input
            type={"tel"}
            placeholder="请填写手机号"
            value={tel}
            maxLength={11}
            onInput={(e) => {
              setTel(e?.target?.value);
            }}
          />
        </div>
      </div>
      <div className="personal_edit_tel_row">
        <span className="personal_edit_tel_row_title">验证码</span>
        <div className="yz_input">
          <input
            type={"tel"}
            placeholder="请填写验证码"
            value={code}
            maxLength={6}
            onInput={(e) => {
              setCode(e?.target?.value);
            }}
          />
          <span
            className={`btn_code color_main_title ${!isGetCode && "no_code"}`}
            onClick={() => {
              if (isGetCode) getCode();
            }}
          >
            {isGetCode ? "获取验证码" : `重新获取${countDown}`}
          </span>
        </div>
      </div>
      <div className="btn">
        <div className="btn_save color_btn" onClick={goNext}>
          <span>绑定</span>
        </div>
      </div>
    </div>
  );
};

export default PersonalBindTel;

import { useState, useEffect, useRef } from 'react' 
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { message } from 'antd'
import YzEmptyMobile from '../../../../../components/mobile/YzEmptyMobile'
import YzBackgroundMobile from '../../../../../components/mobile/YzBackgroundMobile'
import PDExhibitionMobile from '../PDExhibitionMobile'
import PDIntroduceMobile from '../PDIntroduceMobile'
import FooterMobile from '../../../../../components/mobile/FooterMobile'
import BuoyBoxMobile from '../../../../../components/mobile/BuoyBoxMobile'
import HTTP from '../../../../../utils/Http'
import './index.less'

const PDSectionMobile = ({
  bg,
  bg_title,
  bg_content,
  params
}) => {
  const navigate = useNavigate()
  const token = useSelector((state) => state?.App?.token)

  const [data, setData] = useState() // 软件产品详情
  const [loading, setLoading] = useState(false)
  const getData = async () => {  // 获取软件产品详情
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/soft/info"+`?t=${new Date().getTime()}`, {
        token,
        id: params?.id ? params?.id : ''
      })
      if (res.status === 10000) {
          setData(res?.data);
      } else if (res.status === 10007) {
        message.warning({ content: '完成登录查看更多详情', style: { marginTop: '10vh' } })
        navigate(-1)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false)
    }
  }
  useEffect(() => {
    getData()
  }, [])

  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = (animation=true) => { // 置顶事件
    if(animation) {
      let myTimer = setInterval(() => {
        boxRef.current.scrollTop -= 60
        if(boxRef.current.scrollTop<=0){
          boxRef.current.scrollTop = 0
          window.clearInterval(myTimer)
        }
      }, 10)
    } else {
      boxRef.current.scrollTop = 0
    }
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='pdsm_section'>
      <div className='pdsm_box'>
        <YzBackgroundMobile
          title={bg_title}
          content={bg_content}
          backgroundImage={bg}
          isShowButton={true}
        />
        {
          data &&
          data?.id === 0 ?
          <div style={{ flex: 1 }} >
            <YzEmptyMobile />
          </div> :
          <>
            <PDExhibitionMobile data={data} />
            <PDIntroduceMobile data={data} />
          </>
        }
        <FooterMobile isAbout={false} />
        <BuoyBoxMobile isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default PDSectionMobile
import { message } from "antd"
import { store } from "../store"
import { setExpireTime, setToken } from "../store/app"
import { setUserInfo } from "../store/user"
import { setMenuList } from "../store/menu"
import HTTP from "./Http"

export const logout = async () => {
  store.dispatch(setToken())
  store.dispatch(setExpireTime())
  store.dispatch(setUserInfo())
  await getMenuListApi()
}

export const getUserInfo = async (token, callback=()=>{}) => {
  try {
    const res = await HTTP.postForm('/api/v1/user/userInfo'+`?t=${new Date().getTime()}`, { token })
    if (res.status === 10000) {
      await store.dispatch(setUserInfo(res?.data))
      await callback(res?.data)
    } else {
      message.error({ content: res?.message, style: { marginTop: '10vh' } })
    }
  } catch (error) {
    message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
  } finally {
  }
}

const getMenuListApi = async () => {
  try {
    const res = await HTTP.postForm("/api/v1/navigation/listByPosition"+`?t=${new Date().getTime()}`, { position: 1 })
    if (res.status === 10000) {
      let target = res?.data?.map(item => {
        return {
          key: item?.id,
          title: item?.name ?? '标题',
          link: item?.link ?? '/',
          backgroud: item?.backgroud,
          bg_title: item?.title,
          bg_content: item?.content,
          pageRoute: item?.text1,
          pageType: item?.text2,
          category_id: item?.category_id
        }
      })
      store.dispatch(setMenuList(target))
    } else {
      message.error({ content: res?.message, style: { marginTop: '10vh' } })
    }
  } catch (error) {
    message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
  } finally {
  }
}
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { setToken, setExpireTime } from "../../../../../store/app";
import { setMenuList } from "../../../../../store/menu";
import { dataVerification } from "../../../../../utils/formatChecks";
import YzModalTreatyMobile from "../../../../../components/mobile/YzModalTreatyMobile";
// import Image from '../../../../../components/Image'
import HTTP from "../../../../../utils/Http";
import { getUserInfo } from "../../../../../utils/UserAction";
import "./index.less";

const LoginBox = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置

  // 协议弹窗
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [tel, setTel] = useState("");
  const [code, setCode] = useState("");
  const [username, setUsername] = useState("");
  const [pwd1, setPwd1] = useState("");
  const [pwd2, setPwd2] = useState("");

  const [currentPage, setCurrentPage] = useState(0); // 当前页
  const [countDown, setCountDown] = useState(60); // 获取验证码倒计时
  const [isGetCode, setIsGetCode] = useState(true); // 是否可获取验证码
  // 验证码获取倒计时
  const countDownHandle = () => {
    let t = countDown;
    setIsGetCode(false);
    const timer = setInterval(() => {
      t--;
      setCountDown(t);
      if (t === 0) {
        clearInterval(timer);
        setIsGetCode(true);
        setCountDown(60);
      }
    }, 1000);
  };
  const [loading, setLoading] = useState(false);
  const getCode = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    await getVerifyMobileApi();
  };
  const getVerifyMobileApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/verifyMobile" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        // 已注册
        message.warning({
          content: "当前手机号已注册",
          style: { marginTop: "10vh" },
        });
      } else {
        // 未注册
        await getCodeApi();
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getCodeApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/sms/sendCommonSMS" + `?t=${new Date().getTime()}`,
        { mobile: tel }
      );
      if (res.status === 10000) {
        countDownHandle();
        message.success({
          content: "验证码已发送",
          style: { marginTop: "10vh" },
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const goNext = async () => {
    if (tel === "")
      return message.error({
        content: "手机号不能为空",
        style: { marginTop: "10vh" },
      });
    if (dataVerification("tel", tel))
      return message.error({
        content: "手机号格式错误",
        style: { marginTop: "10vh" },
      });
    if (code === "")
      return message.error({
        content: "验证码不能为空",
        style: { marginTop: "10vh" },
      });
    if (code.length < 6)
      return message.error({
        content: "验证码格式错误",
        style: { marginTop: "10vh" },
      });
    setCurrentPage(1);
  };
  const goRegister = async () => {
    if (username === "")
      return message.error({
        content: "用户名不能为空",
        style: { marginTop: "10vh" },
      });
    if (pwd1 === "" || pwd2 === "")
      return message.error({
        content: "密码不能为空",
        style: { marginTop: "10vh" },
      });
    if (pwd1 !== pwd2)
      return message.error({
        content: "两次密码不一致",
        style: { marginTop: "10vh" },
      });
    await goRegisterApi();
  };
  const goRegisterApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/auth/registerByMobile" + `?t=${new Date().getTime()}`,
        {
          mobile: tel,
          code,
          username,
          password: pwd2,
        }
      );
      if (res.status === 10000) {
        // 注册成功
        dispatch(setToken(res?.data?.token));
        dispatch(setExpireTime(res?.data?.expire));
        await getUserInfo(res?.data?.token, async () => {
          await getMenuListApi();
          navigate(-1, { replace: true });
        });
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getMenuListApi = async () => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/listByPosition" + `?t=${new Date().getTime()}`,
        { position: 1 }
      );
      if (res.status === 10000) {
        let target = res?.data?.map((item) => {
          return {
            key: item?.id,
            title: item?.name ?? "标题",
            link: item?.link ?? "/",
            backgroud: item?.backgroud,
            bg_title: item?.title,
            bg_content: item?.content,
            pageRoute: item?.text1,
            pageType: item?.text2,
            category_id: item?.category_id,
          };
        });
        dispatch(setMenuList(target));
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {currentPage === 0 ? (
        <>
          <div className="login_section_m_header">
            <span className="login_section_m_title login_section_m_title_active">
              账号注册
            </span>
          </div>
          <div className="login_section_m_body">
            <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
              <input
                type={"tel"}
                placeholder="请填写手机号"
                value={tel}
                maxLength={11}
                onInput={(e) => {
                  setTel(e?.target?.value);
                }}
              />
            </div>
            <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
              <input
                type={"tel"}
                placeholder="请填写验证码"
                value={code}
                maxLength={6}
                onInput={(e) => {
                  setCode(e?.target?.value);
                }}
              />
              <span
                className={`btn_code_m color_main_title ${
                  !isGetCode && "no_code"
                }`}
                onClick={() => {
                  if (isGetCode) getCode();
                }}
              >
                {isGetCode ? "获取验证码" : `重新获取${countDown}`}
              </span>
            </div>
            {commonConfig.login_switch === "1" && (
              <div className="btn_other2_m">
                <span className="label_m">已有账号？</span>
                <div
                  className="btn_login_m color_main_title"
                  onClick={() => {
                    navigate("/login", { replace: true });
                  }}
                >
                  立即登录
                </div>
              </div>
            )}
            <div className="btn">
              <div className="btn_login_m color_btn" onClick={goNext}>
                下一步
              </div>
            </div>
            <div className="agreement_m">
              <span className="agreement_label_m">注册即表示同意</span>
              <span
                className="btn_agreement_m"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                《注册协议》
              </span>
            </div>
            {/* <span className='third_party_title_m'>第三方账号登录</span>
            <div
              className='btn_third_party_box_m'
              onClick={() => {
                navigate('/login/wechat', { replace: true })
              }}
            >
              <Image src={require('./assets/sys_login_wchat@3x.png')} alt='' />
            </div> */}
          </div>
        </>
      ) : (
        <>
          <div className="login_section_m_header">
            <span className="login_section_m_title login_section_m_title_active">
              设置账号资料
            </span>
          </div>
          <div className="login_section_m_body">
            <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
              <input
                type={"text"}
                placeholder="请填写用户名"
                value={username}
                maxLength={16}
                onInput={(e) => {
                  setUsername(e?.target?.value);
                }}
              />
            </div>
            <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
              <input
                type={"text"}
                placeholder="请设置密码"
                value={pwd1}
                maxLength={16}
                onInput={(e) => {
                  setPwd1(e?.target?.value);
                }}
              />
            </div>
            <div className="yz_input_m" style={{ marginTop: ".4267rem" }}>
              <input
                type={"text"}
                placeholder="请再次填写密码"
                value={pwd2}
                maxLength={16}
                onInput={(e) => {
                  setPwd2(e?.target?.value);
                }}
              />
            </div>
            <div className="btn">
              <div
                className="btn_prev_m"
                onClick={() => {
                  setCurrentPage(0);
                }}
              >
                上一步
              </div>
              <div className="btn_register_m color_btn" onClick={goRegister}>
                注册
              </div>
            </div>
            <div className="agreement_m margin_bottom">
              <span className="agreement_label_m">注册即表示同意</span>
              <span
                className="btn_agreement_m"
                onClick={() => {
                  setIsModalOpen(true);
                }}
              >
                《注册协议》
              </span>
            </div>
          </div>
        </>
      )}
      {/* 协议弹窗 */}
      <YzModalTreatyMobile
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

const LoginSectionMobile = () => {
  return (
    <section
      className="login_section_m"
      style={{
        backgroundImage: `url(${require("./assets/bg_login_m@3x.png")})`,
      }}
    >
      <LoginBox />
    </section>
  );
};

export default LoginSectionMobile;

import Image from "../../../../../components/Image";
import "./index.less";

const PDIntroduceMobile = ({ data }) => {
  return (
    <div className="pdim_introduce">
      <div className="pdim_header">
        <span className="header_title">功能介绍</span>
        <span className="header_content">{data?.content}</span>
      </div>
      <Image src={data?.cover ?? ""} alt="" />
      <div className="list">
        <div className="item" style={{ marginTop: ".64rem" }}>
          <div className="item_header">
            <div className="item_divider color_main" />
            <span className="item_title">应用场景</span>
          </div>
          <div className="item_content">{data?.content1}</div>
        </div>
        <div className="item">
          <div className="item_header">
            <div className="item_divider color_main" />
            <span className="item_title">应用功能</span>
          </div>
          <div className="item_content">{data?.content2}</div>
        </div>
        <div className="item">
          <div className="item_header">
            <div className="item_divider color_main" />
            <span className="item_title">应用特点</span>
          </div>
          <div className="item_content">{data?.content3}</div>
        </div>
      </div>
    </div>
  );
};

export default PDIntroduceMobile;

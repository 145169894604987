import Image from '../../Image'
import './index.less'
const YzEmpty = ({
  marginTop,
  title='暂无数据',
  width='100%',
  height='100%',
  backgroundColor='#FFF',
  image=require('./assets/img_empty_data 1@2x.png')
}) => {
  return (
    <div 
      style={{
        marginTop: marginTop,
        width: width, height: height,
        backgroundColor: backgroundColor
      }}
      className='yz_mpty'
    >
      <div className='yz_mpty_box'>
        <Image src={image} alt='' />
        <span>{title}</span>
      </div>
    </div>
  )
}

export default YzEmpty
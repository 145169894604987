import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Modal, message } from 'antd'
import { setUserInfo } from '../../../store/user'
import QRCode from 'qrcode.react'
import Image from '../../Image'
import HTTP from '../../../utils/Http'
import './index.less'
import { useNavigate } from 'react-router-dom'

let timer;

const YzModalWeChat = ({
  open=false,
  onCancel=() => {}
}) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = useSelector(state => state?.App?.token)
  const userInfo = useSelector(state => state?.User?.userInfo)
  useEffect(() => {
    if(!userInfo) return navigate('/', { replace: true })
  }, [userInfo])

  const [isAuth, setIsAuth] = useState(false)
  const [imgUrl, setImgUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const getWeChatCodeApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/wechatBindUrl'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        setImgUrl(res?.data)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const getUserInfoApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/userInfo'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        if(res?.data?.open_id) {
          onCancel()
          setIsAuth(true)
        }
        await dispatch(setUserInfo(res?.data))
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const getWechatUnBindApi = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm('/api/v1/user/wechatUnBind'+`?t=${new Date().getTime()}`, { token })
      if (res.status === 10000) {
        await getUserInfoApi()
        onCancel()
        setIsAuth(true)
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  const AuthHandle = () => {
    clearInterval(timer)
    if(!isAuth) {
      timer = setInterval(() => {
        getUserInfoApi()
      }, 2000)
    } else {
      clearInterval(timer)
    }
  }
  useEffect(() => {
    if (open) {
      getWeChatCodeApi()
      AuthHandle()
    } else {
      clearInterval(timer)
      getUserInfoApi()
    }
  }, [open, isAuth])

  return (
    <Modal
      className='yz_modal_wechat'
      open={open}
      centered={true}
      closable={false}
      footer={null}
      maskStyle={{ backgroundColor: 'rgba(0,0,0,0.5)' }}
      onCancel={onCancel}
      destroyOnClose={true}
      afterClose={onCancel}
    >
      <div className='modal_close' onClick={onCancel}>
        <Image className='modal_close_img' src={require('./assets/t_icon_top_close@2x.png')} alt='' />
      </div>
      {
        userInfo &&
        userInfo.open_id==='' ?
        <>
          <div className='modal_header'>
            <span className='modal_title'>绑定微信</span>
            <span className='modal_vice_title'>手机微信客户端扫码绑定微信</span>
          </div>
          <div className='modal_body'>
            <div className='modal_item'>
              <div
                className='modal_item_img_box'
                onClick={getWeChatCodeApi}
              >
                <QRCode value={imgUrl} />
              </div>
              <span className='modal_item_title'>
                微信扫码
              </span>
            </div>
          </div>
        </> :
        <>
          <div className='yz_modal_wechat_content'>
            确认解除绑定吗？
          </div>
          <div className='yz_modal_wechat_btn'>
            <div className='btn' onClick={getWechatUnBindApi}>
              <span>确定</span>
            </div>
            <div className='divider'/>
            <div className='btn quxiao' onClick={onCancel}>
              <span>取消</span>
            </div>
          </div>
        </>
      }
    </Modal>
  )
}

export default YzModalWeChat
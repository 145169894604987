import { useNavigate } from "react-router-dom";
import "./index.less";

const NDHeader = ({ title }) => {
  const navigate = useNavigate();
  return (
    <header className="ndh_header">
      <div className="ndh_header_box">
        <span
          className="active color_main_title"
          onClick={() => {
            navigate("/news", { replace: true });
          }}
        >
          {title}
        </span>
        <div className="to_right">&gt;</div>
        <span>正文</span>
      </div>
    </header>
  );
};

export default NDHeader;

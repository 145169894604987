import { useState, useEffect, useRef } from 'react'
import YzBackgroundMobile from '../../../../../components/mobile/YzBackgroundMobile'
import NDHeaderMobile from '../NDHeaderMobile'
import NDMainMobile from '../NDMainMobile'
import FooterMobile from '../../../../../components/mobile/FooterMobile'
import BuoyBoxMobile from '../../../../../components/mobile/BuoyBoxMobile'
import './index.less'

const NDSMobile = ({
  title,
  bg,
  bg_title,
  bg_content,
  category_id
}) => {
  // 置顶
  const boxRef = useRef(null)
  const [scrollTop, setScrollTop] = useState(0)
  const [clientHeight, setClientHeight] = useState(0)
  const goToTop = (animation=true) => { // 置顶事件
    if(animation) {
      let myTimer = setInterval(() => {
        boxRef.current.scrollTop -= 60
        if(boxRef.current.scrollTop<=0){
          boxRef.current.scrollTop = 0
          window.clearInterval(myTimer)
        }
      }, 10)
    } else {
      boxRef.current.scrollTop = 0
    }
  }
  useEffect(() => { // 页面滚动
    window.addEventListener("scroll", (e) => {
      setScrollTop(e?.target?.scrollTop)
      setClientHeight(e?.target?.clientHeight);
    }, true)
  }, [])

  return (
    <section ref={boxRef} className='news_details_section_m'>
      <div className='news_details_box_m'>
        <YzBackgroundMobile
          title={bg_title}
          content={bg_content}
          backgroundImage={bg}
          isShowButton={false}
        />
        <NDHeaderMobile title={title} />
        <NDMainMobile category_id={category_id} />
        <FooterMobile isAbout={false} />
        <BuoyBoxMobile isShowTop={scrollTop > clientHeight} onTop={goToTop} />
      </div>
    </section>
  )
}

export default NDSMobile
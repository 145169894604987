import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination, message } from "antd";
import { useSelector } from "react-redux";
import YzEmptyMobile from "../../../../../components/mobile/YzEmptyMobile";
import YzModalProductMobile from "../../../../../components/mobile/YzModalProductMobile";
import Image from "../../../../../components/Image";
import HTTP from "../../../../../utils/Http";
import "./index.less";

const SearchSortMobile = ({ goToTop = () => {} }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state?.App?.token);
  const userInfo = useSelector((state) => state?.User?.userInfo);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [data, setData] = useState(); // 搜索结果列表
  const [loading, setLoading] = useState(false);
  const getAddCountApi = async (id) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/article/addCount" + `?t=${new Date().getTime()}`,
        { id }
      );
      if (res.status === 10000) {
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getData = async () => {
    // 获取搜索结果列表
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/search" + `?t=${new Date().getTime()}`,
        {
          token,
          key: location?.search ? decodeURI(location?.search.substring(5)) : "",
          page,
          pers: 6,
        }
      );
      if (res.status === 10000) {
        setData(res);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [page, location]);

  return (
    <>
      <div className="search_sort_m">
        <span className="title">为您搜到以下结果：</span>
        {data && data?.data && data?.data?.length > 0 && (
          <div className="list">
            {data?.data?.map((m, index) => (
              <div
                key={"ssm_item_" + index}
                className="item"
                style={{ marginTop: index === 0 && 0 }}
                onClick={async () => {
                  if (m.category === 1) {
                    // 新闻资讯
                    await navigate(`/news/details/1/${m.id}`, {
                      state: { sortId: m?.category_id },
                    });
                    return await getAddCountApi(m.id);
                  }
                  if (m.category === 2) {
                    // 软件产品
                    if (m?.is_login === 1 && !userInfo)
                      return setIsModalOpen(true);
                    return navigate(`/product/details/1/${m?.id}`);
                  }
                }}
              >
                {m.category === 1 && (
                  <div className="yz_tag news_item">新闻资讯</div>
                )}
                {m.category === 2 && (
                  <div className="yz_tag color_main">软件产品</div>
                )}
                <Image src={m?.pic} alt="" />
                <div className="item_box">
                  <span className="item_title">{m?.title}</span>
                  <div className="item_divider color_main" />
                  <span className="item_content">{m?.content}</span>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* {
          data &&
          data?.data?.length > 0 &&
          <div className='pagination'>
            <Pagination
              current={page}
              total={data?.count}
              pageSize={6}
              showQuickJumper={true}
              showSizeChanger={false}
              hideOnSinglePage={true}
              onChange={(pageNumber) => {
                setPage(pageNumber)
                goToTop(false)
              }}
            />
          </div>
        } */}
      </div>
      {data && data?.data === null && (
        <div style={{ flex: 1 }}>
          <YzEmptyMobile backgroundColor="#FAFAFA" />
        </div>
      )}
      <YzModalProductMobile
        open={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
      />
    </>
  );
};

export default SearchSortMobile;

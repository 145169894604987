import { useState, useEffect } from 'react'
import { message } from 'antd'
import Image from '../../Image'
import HTTP from '../../../utils/Http'
import './index.less'

const BuoyBox = ({
  isShowTop=true,
  onTop=() => {}
}) => {
  const [isShow1, setIsShow1] = useState(false) // 弹窗1是否显示
  const [isShow2, setIsShow2] = useState(false) // 弹窗2是否显示

  useEffect(() => { // 点击空白处收起弹窗1
    if(isShow1) {
      window.addEventListener('click', () => {
        setIsShow1(false)
      }, false)
    } else {
      window.removeEventListener('click', () => {}, false)
    }
  }, [isShow1])
  useEffect(() => { // 点击空白处收起弹窗2
    if(isShow2) {
      window.addEventListener('click', () => {
        setIsShow2(false)
      }, false)
    } else {
      window.removeEventListener('click', () => {}, false)
    }
  }, [isShow2])

  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const getData = async () => {
    if (loading) return;
    setLoading(true)
    try {
      const res = await HTTP.postForm("/api/v1/brand/detailByKey"+`?t=${new Date().getTime()}`, { key: 'contact_us' });
      if (res.status === 10000) {
        setData(res?.data?.Gallery_list);
      } else {
        message.error({ content: res?.message, style: { marginTop: '10vh' } })
      }
    } catch (error) {
      message.error({ content: '通信失败，请检查网络后重试', style: { marginTop: '10vh' } })
    } finally {
      setLoading(false);
    }
  }
  useEffect(() => {
    getData()
  }, [])

  return (
    <div className='buoy_box'>
      {/* 按钮1 */}
      <div
        className='btn btn_top'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onMouseOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(true)
          setIsShow2(false)
        }}
        onMouseOut={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(false)
          setIsShow2(false)
        }}
      >
        <Image src={require(isShow1?'./assets/icon_service_t@2x.png':'./assets/icon_service_tn@2x.png')} alt='' />
      </div>
      {/* 按钮2 */}
      <div
        className='btn btn_bottom'
        onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
        }}
        onMouseOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(false)
          setIsShow2(true)
        }}
        onMouseOut={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(false)
          setIsShow2(false)
        }}
      >
        <Image src={require(isShow2?'./assets/img_QRCode_ts@2x.png':'./assets/img_QRCode_t@2x.png')} alt='' />
      </div>
      {/* 置顶按钮 */}
      {isShowTop &&
      <div className='btn btn_to_top' onClick={onTop}>
        <Image src={require('./assets/icon_top@2x.png')} alt='' />
      </div>}
      {/* 弹窗1 */}
      {isShow1 &&
      <div
        className='buoy_box_modal'
        onMouseOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(true)
          setIsShow2(false)
        }}
        onMouseOut={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(false)
          setIsShow2(false)
        }}
      >
        <div className='buoy_box_modal_box'>
          <div className='buoy_box_code_img'>
            <Image src={data && data?.length > 0 && data[0]?.pic} alt='' />
          </div>
          <span>
            {/* 扫一扫，联系客服 */}
            { data && data?.length > 0 && data[0]?.title }
          </span>
        </div>
      </div>}
      {/* 弹窗2 */}
      {isShow2 &&
      <div
        className='buoy_box_modal'
        onMouseOver={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(false)
          setIsShow2(true)
        }}
        onMouseOut={(e) => {
          e.preventDefault()
          e.stopPropagation()
          setIsShow1(false)
          setIsShow2(false)
        }}
      >
        <div className='buoy_box_modal_box'>
          <div className='buoy_box_code_img'>
            <Image src={data && data?.length > 1 && data[1]?.pic} alt='' />
          </div>
          <span>
            {/* 扫一扫，二维码 */}
            { data && data?.length > 1 && data[1]?.title }
          </span>
        </div>
      </div>}
    </div>
  )
}

export default BuoyBox
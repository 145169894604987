// 其他路由项
const routers = [
  // { title: '软件产品详情', link: '/product/details' },
  { title: '搜索结果', link: '/search' },
  { title: '登录', link: '/login' },
  { title: '微信登录', link: '/login/wechat' },
  { title: '绑定手机号', link: '/login/tel_binding' },
  { title: '找回密码', link: '/login/password' },
  { title: '注册', link: '/register' },
  { title: '个人中心', link: '/personal' },
  { title: '注册协议', link: '/treaty' }
]
export const getDocumentTitle = (pathname, menuList, webTitle='', isAddWebTitle=true) => {
  let target = menuList?.map(item => {
    return {
      title: item?.title ?? '',
      link: item?.link ?? '/'
    }
  })
  if(
    [...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title) &&
    [...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)?.length > 0
  ) {
    if ([...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)[0]==='首页') {
      document.title=webTitle
      return webTitle
    } else {
      document.title=`${[[...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)]}-${webTitle}`
      if(isAddWebTitle) return `${[[...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)]}-${webTitle}`
      else return `${[[...target, ...routers].filter(f=>f.link === pathname)?.map(m=>m.title)]}`
    }
  } else {
    document.title=webTitle
    return webTitle
  }
}
import { useNavigate } from "react-router-dom";
import Image from "../../Image";
import "./index.less";

const LoginHeaderMobile = () => {
  const navigate = useNavigate();

  return (
    <header className="header_mobile_m color_header">
      <div
        className="logo"
        onClick={() => {
          navigate("/");
        }}
      >
        <Image
          className="logo_img"
          src={require("./assets/logo@3x.png")}
          alt=""
        />
      </div>
      <div
        className="btn_close"
        onClick={() => {
          navigate(-1);
        }}
      >
        <Image src={require("./assets/t_icon_top_close@3x.png")} alt="" />
      </div>
    </header>
  );
};

export default LoginHeaderMobile;

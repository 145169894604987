import { message } from "antd";
import qs from "qs";
import axios from "axios";
import { store } from "../store";
import { setToken, setExpireTime } from "../store/app";
import { setUserInfo } from "../store/user";
import moment from "moment";
import { logout } from "../utils/UserAction";

const service = axios.create({
  timeout: 120000,
  baseURL: window.myURL.URL,
});

service.interceptors.request.use(
  async (config) => {
    const { App } = store.getState();
    // 定制请求头部固定信息
    if (App.token) {
      config.headers = {
        Authorization: "Bearer " + App.token,
        // 'Content-Type': 'application/x-www-form-urlencoded',
      };
    }
    if (new RegExp(/token/).test(config.data)) {
      // 校验token是否过期 token过期时间小于当前时间时
      if (App.expireTime && App.expireTime - 0 < moment().unix()) {
        logout();
        message.warning({
          content: "您的登录信息已过期",
          style: { marginTop: "10vh" },
        });
      }
    }
    return config;
  },
  (error) => {
    // 对响应错误做点什么
    console.log(error);
    return Promise.reject(error);
  }
);
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      return Promise.reject({ status: 99888, ...error });
    } else {
      if (error.response && error.response.status) {
        switch (error.response.status) {
          case 403:
            store.dispatch(setToken());
            store.dispatch(setExpireTime());
            store.dispatch(setUserInfo());
            window.location = "/";

            break;
          case 611:
            window.location = "/auth";
            break;
          default:
            break;
        }
      }
      return Promise.reject(error);
    }
    // 对响应错误做点什么
  }
);

export default class HTTP {
  static getFullUrl(url, timeout) {
    return new Promise((resolve, reject) => {
      service
        .get(url, {
          timeout: timeout || 120000,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(`HTTP Response error ${url}:`, JSON.stringify(error));
          reject(error);
        });
    });
  }

  static postFullUrl(url, data, timeout) {
    return new Promise((resolve, reject) => {
      service
        .post(url, qs.stringify(data), {
          timeout: timeout || 120000,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(`HTTP Response error ${url}:`, JSON.stringify(error));
          reject(error);
        });
    });
  }

  static get(url, params, timeout) {
    return new Promise((resolve, reject) => {
      service
        .get(url, {
          timeout: timeout || 120000,
          params: params,
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          console.warn(`HTTP Response error ${url}:`, JSON.stringify(error));
          reject(error);
        });
    });
  }

  static post(
    url,
    data,
    timeout = 120000,
    token = undefined,
    allowRepeated = true
  ) {
    return new Promise((resolve, reject) => {
      console.debug(`HTTP Required ${url}:`, data);
      service
        .post(url, data, {
          timeout: timeout,
          allowRepeated: allowRepeated,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/json",
          },
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          if (error?.status !== 99888) {
            console.debug(`HTTP Response error ${url}:`, JSON.stringify(error));
          }
          reject(error);
        });
    });
  }

  static postForm(
    url,
    data,
    timeout = 120000,
    token = undefined,
    allowRepeated = true
  ) {
    return new Promise((resolve, reject) => {
      console.debug(`HTTP Required ${url}:`, data);
      service
        .post(url, qs.stringify(data), {
          timeout: timeout,
          allowRepeated: allowRepeated,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "application/x-www-form-urlencoded",
          },
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          if (error?.status !== 99888) {
            console.debug(`HTTP Response error ${url}:`, JSON.stringify(error));
          }
          reject(error);
        });
    });
  }

  static uploadFile(url, fileUrl, timeout, token = undefined) {
    return new Promise((resolve, reject) => {
      console.debug(`HTTP Required ${url}:`, fileUrl);
      let formData = new FormData();
      formData.append("file", fileUrl);

      service
        .post(url, formData, {
          timeout: timeout,
          headers: {
            Authorization: "Bearer " + token,
            "Content-Type": "multipart/form-data;charset=UTF-8",
          },
        })
        .then((result) => {
          console.debug(`HTTP Response ${url}:`, result.data);
          resolve(result.data);
        })
        .catch((error) => {
          // 处理错误
          if (error?.status !== 99888) {
            console.debug(`HTTP Response error ${url}:`, JSON.stringify(error));
          }
          reject(error);
        });
    });
  }
}

import { useState, useEffect } from 'react'
import { message } from 'antd'
import Image from '../../Image'
import HTTP from '../../../utils/Http'
import './index.less'

const BuoyBoxMobile = ({
  isShowTop=true,
  onTop=() => {}
}) => {
  return (
    <div className='buoy_box_m'>
      {/* 客服按钮 */}
      <a
        className='btn_m btn_top_m'
        onClick={() => {  }}
        href='https://work.weixin.qq.com/kfid/kfc4e4b3fc70c01305d'
      >
        <Image src={require('./assets/icon_service_t@3x.png')} alt='' />
      </a>
      {/* 置顶按钮 */}
      {isShowTop &&
      <div className='btn_m btn_to_top_m' onClick={onTop}>
        <Image src={require('./assets/icon_top@3x.png')} alt='' />
      </div>}
    </div>
  )
}

export default BuoyBoxMobile
import { useRef } from 'react'
import './index.less'

const Image = ({
  width,
  height,
  src,
  style,
  alt,
  className,
  spare=''
}) => {
  const imgRef = useRef(null)
  return (
      <img
        ref={imgRef}
        width={width}
        height={height}
        src={src}
        style={{...style}}
        alt={alt}
        draggable={false}
        onError={() => { if (imgRef && imgRef.current && imgRef.current.src) imgRef.current.src=spare }}
        className={className ? className+' my_image': 'my_image'}
      />
  )
}

export default Image
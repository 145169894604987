import { createSlice } from '@reduxjs/toolkit'
import qs from 'qs'

export const appSlice = createSlice({
  name: 'app',
  initialState: {
    token: localStorage.getItem('token') &&
           localStorage.getItem('token') !=='' ? localStorage.getItem('token') : null,
    commonConfig: localStorage.getItem('commonConfig') &&
                  localStorage.getItem('commonConfig') !== '' ? qs.parse(localStorage.getItem('commonConfig')) : null,
    expireTime: localStorage.getItem('expireTime') &&
                localStorage.getItem('expireTime') !== '' ? localStorage.getItem('expireTime') : null
  },
  reducers: {
    setToken: (state, action) => {
      if (action.payload) {
        state.token = action.payload
        localStorage.setItem('token', action.payload)
      } else {
        state.token = null
        localStorage.removeItem('token')
      }
    },
    setCommonConfig: (state, action) => {
      state.commonConfig = action.payload
      localStorage.setItem('commonConfig', qs.stringify(action.payload))
    },
    setExpireTime: (state, action) => {
      if (action.payload) {
        state.expireTime = action.payload
        localStorage.setItem('expireTime', action.payload)
      } else {
        state.expireTime = null
        localStorage.removeItem('expireTime')
      }
    }
  }
})

export const {
  setToken,
  setCommonConfig,
  setExpireTime
} = appSlice.actions

export default appSlice.reducer
export const loaderComponent = (lazyComponent, attemptsLeft = 3, interval = 1000) => {
  return new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((err) => {
        setTimeout(() => {
          if (attemptsLeft === 1) return reject(err)
          loaderComponent(lazyComponent, attemptsLeft - 1, interval).then(resolve, reject)
        }, interval)
      })
  })
}
import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Pagination, message } from "antd";
import { useSelector } from "react-redux";
import YzModalLogin from "../../../../../components/pc/YzModalLogin";
import YzModalProduct from "../../../../../components/pc/YzModalProduct";
import YzEmpty from "../../../../../components/pc/YzEmpty";
import Image from "../../../../../components/Image";
import HTTP from "../../../../../utils/Http";
import "./index.less";

const SearchSort = ({ goToTop = () => {} }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = useSelector((state) => state?.App?.token);
  // 用户
  const userInfo = useSelector((state) => state?.User?.userInfo); // 用户信息
  // 系统
  const commonConfig = useSelector((state) => state?.App?.commonConfig); // 系统配置

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpen2, setIsModalOpen2] = useState(false);

  const [page, setPage] = useState(1);
  const [data, setData] = useState(); // 搜索结果列表
  const [loading, setLoading] = useState(false);
  const getAddCountApi = async (id) => {
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/article/addCount" + `?t=${new Date().getTime()}`,
        { id }
      );
      if (res.status === 10000) {
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  const getData = async () => {
    // 获取搜索结果列表
    if (loading) return;
    setLoading(true);
    try {
      const res = await HTTP.postForm(
        "/api/v1/navigation/search" + `?t=${new Date().getTime()}`,
        {
          token,
          key: location?.search ? decodeURI(location?.search.substring(5)) : "",
          page,
          pers: 15,
        }
      );
      if (res.status === 10000) {
        setData(res);
      } else {
        message.error({ content: res?.message, style: { marginTop: "10vh" } });
      }
    } catch (error) {
      message.error({
        content: "通信失败，请检查网络后重试",
        style: { marginTop: "10vh" },
      });
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, [page, location]);

  return (
    <>
      <div className="search_sort">
        <span className="search_sort_label">为您搜到以下结果：</span>
        {/* 展示列表 */}
        {data && data?.data && data?.data?.length > 0 && (
          <div className="search_sort_list">
            {data?.data?.map((m, index) => (
              <div
                className={`search_sort_item`}
                key={"search_sort_item_" + index}
              >
                <div
                  className={`search_sort_item_box`}
                  onClick={async () => {
                    if (m.category === 1) {
                      // 新闻资讯
                      await navigate(`/news/details/1/${m.id}`, {
                        state: { sortId: m?.category_id },
                      });
                      return await getAddCountApi(m.id);
                    }
                    if (m.category === 2) {
                      // 软件产品
                      // if (m?.is_login === 1) {
                      //   if (!userInfo || userInfo?.mobile === "")
                      //     return setIsModalOpen(true);
                      // }
                      if (m?.is_login === 1) {
                        if (!userInfo || userInfo?.mobile === "") {
                          if (commonConfig?.wechat_scan_login === "1")
                            return setIsModalOpen(true);
                          return setIsModalOpen2(true);
                        }
                      }
                      navigate(`/product/details/1/${m?.id}`);
                    }
                  }}
                >
                  {m.category === 1 && (
                    <div className="yz_tag news_item">新闻资讯</div>
                  )}
                  {m.category === 2 && (
                    <div className="yz_tag color_main">软件产品</div>
                  )}
                  <Image className="search_sort_img" src={m?.pic} alt="" />
                  <div className="search_sort_box">
                    <span className="search_sort_title">{m?.title}</span>
                    <div className="search_sort_divider color_main" />
                    <span className="search_sort_content" title={m?.content}>
                      {m?.content}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {/* 分页器 */}
        {/* {
        data &&
        data?.data?.length > 0 &&
        <div className='search_sort_pagination'>
          <Pagination
            current={page}
            total={data?.count}
            pageSize={15}
            showQuickJumper={true}
            showSizeChanger={false}
            hideOnSinglePage={true}
            onChange={(pageNumber) => {
              setPage(pageNumber)
              goToTop(false)
            }}
          />
        </div>
      } */}
      </div>
      {data && data?.data === null && (
        <div style={{ flex: 1 }}>
          <YzEmpty backgroundColor="#FAFAFA" />
        </div>
      )}
      {/* 微信登录 */}
      <YzModalLogin open={isModalOpen} onCancel={() => setIsModalOpen(false)} />
      {/* 账号密码登录 */}
      <YzModalProduct
        open={isModalOpen2}
        onCancel={() => setIsModalOpen2(false)}
      />
    </>
  );
};

export default SearchSort;

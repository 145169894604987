import { configureStore } from '@reduxjs/toolkit'
import appReducer from './app'
import menuReducer from './menu'
import userReducer from './user'

export const store = configureStore({
  reducer: {
    App: appReducer,
    Menu: menuReducer,
    User: userReducer
  }
})